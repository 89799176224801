import store from '@/store/index'
const listTaxPermission = store.getters['accountState/hasPermission'](
  'properties',
  'taxInvoices',
  'list'
)
const listContractPermission = store.getters['accountState/hasPermission'](
  'properties',
  'contracts',
  'list'
)
const listBondPermission = store.getters['accountState/hasPermission'](
  'properties',
  'bonds',
  'list'
)

export const clients = {
  exceed: {
    title:
      'لقد  استنفذت العدد المحدد من الإضافات المتاحة في باقتك الحالية وبإمكانك ترقية الباقة لإجراء إضافات جديدة',
    subTitle:
      'يمكنك ترقية الباقة  الحالية  لباقة أعلى أو التواصل معنا مباشرة في حال كان اشتراكك الحالي في أعلى باقة متاحة'
  },
  filterStatus: ['اسم العميل', 'رقم الهوية', 'مستأجر', 'مشتري']
}

export const client = {
  actions: [
    {
      name: {
        owner: 'إضافة عقد',
        renter: 'إضافة عقد إيجار'
      },
      color: 'primary',
      img: require('@/assets/img/svgs/purple-contracts.svg'),
      imgResponsive: require('@/assets/img/svgs/purple-contracts.svg'),
      method: 'showAddContractModal',
      title: 'contracts',
      needPermession: true,
      dataTest: 'action-contract'
    },
    {
      name: 'إضافة سند',
      color: 'rib',
      img: require('@/assets/img/svgs/purple-bonds-icon.svg'),
      imgResponsive: require('@/assets/img/svgs/purple-bonds-icon.svg'),
      method: 'showAddBondModal',
      title: 'bonds',
      needPermession: true,
      dataTest: 'action-bond'
    },
    {
      name: 'سجل نشاطات',
      color: 'teal',
      img: require('@/assets/img/activity-log-icons/purple-activity-icon.svg'),
      imgResponsive: require('@/assets/img/activity-log-icons/purple-activity-icon.svg'),
      method: 'showActivityLogs',
      title: 'activity'
    },
    {
      name: 'إرسال رسالة',
      img: require('@/assets/img/svgs/purple-message.svg'),
      imgResponsive: require('@/assets/img/svgs/purple-message.svg'),
      method: 'showSendMessageModal',
      title: 'message'
    }
  ],
  fileListCard: {
    smsMessages: {
      componentName: 'ClientDetailsFileList',
      noData: 'لا توجد رسائل نصية مرسله لهذا العميل',
      title: 'قائمة إشعارات المطالبات',
      modal: 'showNotificationModal',
      fileListLoading: false,
      isAddAction: true
    },
    bankAccounts: {
      componentName: 'ClientDetailsFileList',
      noData: 'لا توجد حسابات بنكية لهذا العميل',
      title: 'قائمة حسابات العميل',
      modal: 'showBankAccountsModel',
      hasAddPermission: true,
      fileListLoading: false,
      isAddAction: true
    },
    taxes: {
      title: 'الفواتير الضربيه',
      subTitle: 'tax',
      hasAddPermission: true,
      componentName: 'ClientDetailsFileList',
      noData: listTaxPermission
        ? 'لا توجد فواتير ضربيه مرسله لهذا العميل'
        : 'ليس لديك صلاحيه لتصفح الفواتير',
      modal: 'showTaxInfoiceModel',
      fileListLoading: false,
      link: {
        name: 'إظهار كل الفواتير',
        url: '/dashboard/taxInvoices'
      }
    },
    documents: {
      hasAddPermission: true,
      componentName: 'ClientDetailsFileList',
      noData: 'لا توجد وثائق مرسله لهذا العميل',
      title: 'قائمة الوثائق',
      modal: 'documentFileUploader',
      showDocuments: true,
      fileListLoading: false
    },
    contracts: {
      title: 'قائمة العقود',
      subTitle: 'contract',
      link: { name: 'إظهار كل عقود العميل', url: '/dashboard/contracts' },
      hasAddPermission: true,
      modal: 'showAddContractModal',
      noData: listContractPermission
        ? 'لا توجد عقود مرسله لهذا العميل'
        : 'ليس لديك صلاحية لتصفح العقود',
      componentName: 'ClientDetailsFileList',
      fileListLoading: false
    },
    bonds: {
      title: 'قائمة السندات',
      subTitle: 'bond',
      link: { name: 'إظهار كل سندات العميل', url: '/dashboard/bonds' },
      hasAddPermission: true,
      componentName: 'ClientDetailsFileList',
      modal: 'showAddBondModal',
      noData: listBondPermission
        ? 'لا توجد سندات مرسله لهذا العميل'
        : 'ليس لديك صلاحية لتصفح السندات',
      fileListLoading: false
    },
    financialInfo: {
      title: 'المعلومات الماليه',
      componentName: 'financialDetailsFileList',
      noData: 'لا توجد معلومات ماليه لهذا العميل',
      fileListLoading: false
    }
  }
}
