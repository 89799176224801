<template>
  <main class="page-main-bg half-circle-bg" id="clientDetailsPage">
    <!-- Wrapper -->
    <div class="d-flex tab__flex__wrap mobile__flex__wrap">
      <!-------------------------------------------->
      <!-------------Side action-------------------->
      <!-------------------------------------------->

      <div
        class="d-flex flex-column ml-6 mt-16 w-115 tab__mt__15px"
        v-if="!menuState"
      >
        <SideAction
          v-bind="sideAction"
          @openInfoClientModal="toggleModel('dialogStateForClient', true)"
        />
      </div>

      <!-------------------------------------------->
      <!---------------Main page-------------------->
      <!-------------------------------------------->

      <v-container class="px-0">
        <!-------------------------------------------->
        <!---------------Breadcrumbs------------------>
        <!-------------------------------------------->

        <tool-bar
          class="pl-3"
          v-bind="toolBarProps"
          @setDate="setDate"
          @toggleCards="toggleShowStats"
        />

        <v-row class="w-100">
          <v-col cols="12" class="pa-0">
            <!---------------------------------------->
            <!---------Client details toolbar--------->
            <!---------------------------------------->
            <ClientDetailsToolbar
              v-bind="clientDetailsToolbar"
              @openAddTagModel="openInfoClientModal"
              @openModelInviteClient="openModelInviteClient"
            />
          </v-col>

          <!------------------------------------->
          <!---------Model invite client--------->
          <!------------------------------------->
          <customerInviteModel
            :dialogState_="status.showModelInviteClient"
            :customer="client"
            :isLoading="isLoading"
            @inviteClient="inviteClient"
            @closeDialog="closeAddDialog"
          />

          <v-col cols="12" class="px-0">
            <!---------------------------------------->
            <!---------Client details tiles----------->
            <!---------------------------------------->

            <ClientDetailsTiles
              v-if="$hasPermission('statistics', 'statistics', 'list')"
              v-show="cardsInfoState"
              v-bind="clientTilesProps"
              class="mb-2 client__details__cards w-100 mx-0"
              @refreshStateCards="refreshState += 1"
            />
          </v-col>
        </v-row>

        <v-row class="mt-5 client__details-row w-100">
          <!------------------------------------------->
          <!---------Client details info card---------->
          <!------------------------------------------->

          <v-col class="pa-0 customer__information" sm="12">
            <ClientDetailsInfoCard
              :clientDetailsInfo="clientDetailsInfo"
              @dialogState="toggleModel('dialogStateForClient', true)"
            />
          </v-col>

          <!------------------------------------------------->
          <!---------Client details real estate list--------->
          <!------------------------------------------------->

          <v-col class="mt-4 px-0" md="12">
            <ClientDetailsRealEstateList
              v-bind="{ client: true, user: id, selectedRole: setClickedTag }"
              @realEstates="getRealEstates"
              :getPropertyMeta="getPropertyMeta"
              :filterID="filterID"
              :clientId="id"
              @showOwnerRealEstates="showOwnerRealEstates = $event"
              @realEstatesResponse="setRealEstatesResponse"
            />
          </v-col>
        </v-row>

        <v-row class="w-100">
          <!---------Show bonds--------->
          <v-col class="pr-md-0 mobile__flex__auto" md="4" sm="12">
            <FileListCard
              v-bind="listBondsProps"
              ref="FileListCardBond"
              @showAddBondModal="toggleModel('showAddBondModal', true)"
              @nextPageTrigger="callNextPage"
            />
          </v-col>

          <!---------Show contracts--------->
          <v-col md="4" sm="12" class="mobile__flex__auto">
            <FileListCard
              v-bind="listContractsProps"
              ref="FileListCardContract"
              @showAddContractModal="toggleModel('showAddContractModal', true)"
              @nextPageTrigger="callNextPage"
            />
          </v-col>

          <!---------Show taxes--------->
          <v-col md="4" sm="12" class="mobile__flex__auto">
            <FileListCard
              v-bind="listTaxInvoicesProps"
              @showTaxInfoiceModel="toggleModel('showTaxInfoiceModel', true)"
              @nextPageTrigger="callNextPage"
            />
          </v-col>

          <!-----Show financialCard----->
          <!-- <v-col class="pr-md-0 mobile__flex__auto" md="4" sm="12">
            <FileListCard
              v-bind="{
                files: data.allFinancialData,
                hasAddPermission: false,
                ...fileListCard.financialInfo
              }"
              @nextPageTrigger="callNextPage"
              @financialActiveTabChanged="setFinancialActiveTab"
            />
          </v-col> -->

          <!---------Show documents----->
          <!--Remove pr-md-0 class and set md to 4 if financial card will be visible again-->
          <v-col class="mobile__flex__auto pr-md-0" md="4" sm="12">
            <FileListCard
              v-bind="{ files: data.allDocuments, ...fileListCard.documents }"
              @documentFileUploader="openDocumentsInput"
              @delete-document="deleteDocument"
            />
          </v-col>

          <!---------Show CLient Bank Accounts messages--------->
          <v-col class="mobile__flex__auto" md="4" sm="12">
            <FileListCard
              v-bind="bankAccountsProps"
              @showBankAccountsModel="
                toggleModel('showBankAccountsModel', true)
              "
            >
              <template v-slot:bankAccountsContent>
                <div
                  class="bg--F7F7F7 py-1 px-3 rounded-lg my-2"
                  v-for="(account, index) in data.allBankAccounts"
                  :key="account?._id"
                >
                  <div class="d-flex justify-space-between">
                    <p class="text--101010 my-1 font-14">
                      {{ account.bankName }}
                    </p>
                    <div>
                      <v-icon
                        class="mx-1 pointerCursor"
                        size="18"
                        @click="setBankAccountDetails(account)"
                      >
                        mdi-pencil-outline
                      </v-icon>
                      <v-icon
                        class="mx-1 pointerCursor"
                        size="18"
                        @click="confirmDeletion(account, index)"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </div>
                  </div>
                  <p class="text--101010 my-1 font-12">{{ account.name }}</p>
                  <p class="text--101010 my-1 font-12">{{ account.iban }}</p>
                </div>
              </template>
            </FileListCard>
          </v-col>

          <!---------Show alerts --------->
          <v-col md="4" sm="12" class="mobile__flex__auto">
            <FileListCard
              v-bind="smsMessagesProps"
              @showNotificationModal="
                toggleModel('showNotificationModal', true)
              "
              @nextPageTrigger="callNextPage"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!------------------------>
    <!---------Modals--------->
    <!------------------------>

    <!-------------------------------------->
    <!---------1 - Add client modal--------->
    <!-------------------------------------->

    <add-client-modal
      v-if="status.dialogStateForClient"
      v-bind="addClientModal"
      @close-client-dialog="toggleModel('dialogStateForClient', false)"
    />

    <!---------------------------------------->
    <!---------2 - Add contract modal--------->
    <!---------------------------------------->
    <AddContractModal
      @refreshContracts="closeContractDialog"
      @closeContractModal="toggleModel('showAddContractModal', false)"
      v-bind="addContractProps"
      v-if="status.showAddContractModal"
    />

    <!-------------------------------------->
    <!---------3 - Add bond modal----------->
    <!-------------------------------------->

    <add-bond-modal
      v-if="status.showAddBondModal"
      v-bind="addBondProps"
      @closed-bond-dialog="toggleModel('showAddBondModal', false)"
      @refreshBonds="refreshBonds"
    />

    <!-------------------------------------->
    <!---------4 - Add tax invoice---------->
    <!-------------------------------------->

    <add-tax-invoices
      v-if="status.showTaxInfoiceModel"
      v-bind="{
        dialogState: status.showTaxInfoiceModel,
        fromTaxInvoicesPage: false
      }"
      @close-tax-dialog="toggleModel('showTaxInfoiceModel', false)"
      @refreshTaxes="refreshTaxes"
    />

    <ActivityLogsSideDetails
      v-if="status.showActivityLogs"
      :showActivityLogs="status.showActivityLogs"
      @closeActivityLogs="toggleModel('showActivityLogs', false)"
    />

    <SendMessageModal
      v-if="status.showSendMessageModal"
      :client="client"
      :dialogState="status.showSendMessageModal"
      @closeMessageModal="toggleModel('showSendMessageModal', false)"
    />

    <sendNotificationToClientModal
      v-if="status.showNotificationModal"
      v-bind="notificationModelProps"
      @changeDialogState="toggleModel('showNotificationModal', false)"
      @setConstantDataModel="setConstantDataModel"
      @refreshNotification="getAllSmsMessages"
    />

    <bankAccountsAddModel
      v-if="status.showBankAccountsModel"
      :dialogState="status.showBankAccountsModel"
      :clientId="this.client?._id"
      @changeDialogState="toggleModel('showBankAccountsModel', false)"
      @refreshBankList="getClientBankAccounts"
    />

    <bankAcountsEditModel
      v-if="editBankAccountDialogState"
      :bankAccountDetails="bankAccountDetails"
      :dialogState="editBankAccountDialogState"
      @changeDialogState="editBankAccountDialogState = $event"
      @updateBankAccountsList="getClientBankAccounts"
    />

    <!---------Add new file---------->
    <form enctype="multipart/form-data" v-show="false">
      <v-file-input
        ref="documentsInput"
        truncate-length="15"
        prepend-icon="mdi-plus"
        hide-input
        @change="addDocument"
      >
      </v-file-input>
    </form>
  </main>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { client } from '@/constans/clients'
import { taxBillsService } from '@/services/taxBills.service.js'
import { customersService } from '@/services'
import {
  userService,
  contractsService,
  bondsService,
  realEstateService,
  messageService
} from '@/services'
import ClientDetailsInfoCard from '@/components/details/ClientDetailsInfoCard'
import FileListCard from '@/components/details/FileListCard'
import ClientDetailsTiles from '@/components/details/ClientDetailsTiles'
import ClientDetailsRealEstateList from '@/components/details/ClientDetailsRealEstateList'
import ClientDetailsToolbar from '@/components/details/ClientDetailsToolbar'
import SideAction from '@/components/clients/SideAction'
import AddContractModal from '@/components/add-contract/contractModal'
import ToolBar from '@/components/listing/ToolBar'
import { EnvService } from '../services/env.service'

export default {
  name: 'Client',
  components: {
    AddBondModal: () => import('@/components/modals/AddBondModal.vue'),
    AddClientModal: () => import('@/components/modals/AddClientModal'),
    AddTaxInvoices: () => import('@/components/modals/AddTaxInvoices.vue'),
    ActivityLogsSideDetails: () =>
      import('@/components/ActivityLogs/ActivityLogsSideDetails'),
    customerInviteModel: () =>
      import('@/components/profile/customer-invite-model.vue'),
    ClientDetailsToolbar,
    ClientDetailsRealEstateList,
    ClientDetailsTiles,
    FileListCard,
    ClientDetailsInfoCard,
    SideAction,
    AddContractModal,
    SendMessageModal: () => import('@/components/modals/SendMessageModal.vue'),
    bankAccountsAddModel: () =>
      import('@/components/core/bankAccountsAddModel.vue'),
    bankAcountsEditModel: () =>
      import('@/components/core/bankAcountsEditModel.vue'),
    sendNotificationToClientModal: () =>
      import('@/components/core/sendNotificationToClientModal.vue'),
    ToolBar
  },
  data() {
    return {
      ...client,
      claimsTypes: [],
      notificationTypes: [],
      cardsInfoState: true,
      datesQuery: {
        startDate: '',
        endDate: ''
      },
      breadcrumbs: [
        {
          text: 'قائمة العملاء',
          disabled: false,
          exact: true,
          link: true,
          to: {
            path: '/dashboard/clients'
          }
        }
      ],
      data: {
        allContracts: [],
        allBonds: [],
        allTaxes: [],
        allDocuments: [],
        allSmsMessages: [],
        allBankAccounts: [],
        allFinancialData: []
      },
      // Status modals
      status: {},
      // Pagination
      paginationTaxes: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      paginationContracts: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      paginationSms: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      paginationBonds: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      paginationFinancial: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      financialTabsLimitCounts: [],
      activeFinancialTabIndex: 0,
      // Other
      realEstatesResponse: 0,
      realEstatesCount: 0,
      client: {},
      editedBond: {},
      setClickedTag: '',
      addTag: false,
      showOwnerRealEstates: true,
      realEstates: [],
      filterID: '',
      isLoading: false,
      selectedRealEstate: '',
      smsMessagesCount: 0,
      contractIntervals: [],
      statusInviteClient: {},
      financialData: [],
      refreshState: 0,
      clientRoles: {
        renter: 'tenet',
        owner: 'owner'
      },
      avaliableCustomerModules: {
        reports: ['list'],
        taxInvoices: ['list'],
        bonds: ['list', 'update'],
        assignments: ['list', 'update'],
        contracts: ['list', 'update'],
        realestates: ['list', 'update'],
        requests: ['list', 'add', 'update', 'delete']
      },
      bankAccountDetails: {},
      editBankAccountDialogState: false
    }
  },
  async created() {
    this.$emit('updateInformativeTip', this.$route.meta?.informativeTip)
    await this.loadClient()
    this.clientInvitationStatus()
    this.getPropertyMeta()
    this.getContractIntervals()
    // Vuex
    this.setClientForRoute(this.client)
    this.setClientTypeForRoute(this.setClickedTag)
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user', 'configs']),
    notificationModelProps() {
      return {
        dialogState: this.status.showNotificationModal,
        client: this.client,
        contracts: this.data.allContracts,
        setClickedTag: this.setClickedTag,
        existedClaimsTypes: this.claimsTypes,
        existedNotificationTypes: this.notificationTypess
      }
    },
    toolBarProps() {
      return {
        breadcrumbs: this.breadcrumbs,
        showDateFilter: true,
        title: this.client.name,
        cardsInfoState: this.cardsInfoState
      }
    },
    customerPermissions() {
      const customerPermissions = []
      this.configs.permissions.map((module) => {
        if (
          this.avaliableCustomerModules.hasOwnProperty(module.route) &&
          this.avaliableCustomerModules[module.route].includes(
            module.permission
          )
        ) {
          customerPermissions.push(module._id)
        }
      })
      return customerPermissions
    },
    clientTilesProps() {
      return {
        clientId: this.client._id,
        owner: this.renter,
        fromDetails: true,
        refreshState: this.refreshState,
        userType: this.setClickedTag,
        datesQuery: {
          startDate:
            this.datesQuery?.startDate ||
            new Date(new Date().setMonth(new Date().getMonth() - 1)),
          endDate: this.datesQuery?.endDate || new Date()
        }
      }
    },
    addBondProps() {
      return {
        dialogState: this.status.showAddBondModal,
        defaultValues: {
          client: this.client,
          setClickedTag: this.setClickedTag
        }
      }
    },
    addContractProps() {
      return {
        contractIntervals: this.contractIntervals,
        contractModalState: this.status.showAddContractModal,
        defaultData: this.client,
        preSelectedContractType:
          this.setClickedTag === 'renter' ? { type: { nameEn: 'rent' } } : {}
      }
    },
    activeFinancialTabsLimitCounts() {
      return this.financialTabsLimitCounts[this.activeFinancialTabIndex]
    },
    menuState() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return true
        default:
          return false
      }
    },
    phoneNumberString() {
      return '(+٩٦٦) ١٢٥٦ ٨٧٢٣'
    },
    id() {
      return this.$route.params.id
    },
    renter() {
      return this.setClickedTag
    },
    sideAction() {
      return {
        client: this.client,
        clientChange: this.clientChange,
        clickedTag: this.setClickedTag
      }
    },
    isEnvOwner() {
      return (
        !!this.currentEnv?.isOwner ||
        !!(this.currentEnv?.employeeRole === 'owner')
      )
    },
    clientDetailsToolbar() {
      return {
        client: true,
        actions: this.filteredActions,
        menuState: this.menuState,
        realEstatesResponse: this.realEstatesResponse,
        contractsResponse: this.data.allContracts.length,
        statusInviteClient: this.statusInviteClient,
        role: this.setClickedTag,
        toolbarInfo: this.client
      }
    },
    addClientModal() {
      return {
        dialogState: this.status.dialogStateForClient,
        clientData: this.client,
        editClientId: this.client._id,
        addTag: false,
        clientDetaile: true,
        loadClient: this.loadClient,
        realEstates: this.realEstates,
        clientChange: this.clientChange
      }
    },
    clientDetailsInfo() {
      return {
        infoCard: this.client,
        contractsLength: this.data.allContracts.length,
        role: this.setClickedTag,
        title: 'معلومات العميل',
        isEnvOwner: this.isEnvOwner
      }
    },
    smsMessagesProps() {
      return {
        files: this.data.allSmsMessages,
        ...this.fileListCard.smsMessages,
        link: {
          name: 'عرض الكل',
          url: `/dashboard/smsMessages`
        },
        hasAddPermission: this.$hasPermission('users', 'notifications', 'add'),
        client: this.client,
        messagesCount: this.smsMessagesCount
      }
    },
    bankAccountsProps() {
      return {
        files: this.data.allBankAccounts,
        ...this.fileListCard.bankAccounts,
        hasAddPermission: this.$hasPermission(
          'users',
          'bankAccountsSettings',
          'add'
        ),
        hasListPermission: this.$hasPermission(
          'users',
          'bankAccountsSettings',
          'list'
        ),
        client: this.client
      }
    },
    inviteBody() {
      const { phoneNumber, _id, email } = this.client
      const body = {
        phoneNumber,
        email,
        environmentId: this.currentEnv._id,
        customerId: _id,
        permissions: this.customerPermissions
      }
      return body
    },
    listBondsProps() {
      return {
        files: this.data.allBonds,
        ...this.fileListCard.bonds,
        link: {
          ...this.fileListCard.bonds.link,
          client: {
            _id: this.client?._id,
            name: this.client?.name
          }
        },
        hasAddPermission: this.$hasPermission('properties', 'bonds', 'add')
      }
    },
    listContractsProps() {
      return {
        files: this.data.allContracts,
        ...this.fileListCard.contracts,
        link: {
          ...this.fileListCard.contracts.link,
          client: {
            _id: this.client?._id,
            name: this.client?.name
          }
        },
        hasAddPermission: this.$hasPermission('properties', 'contracts', 'add')
      }
    },
    listTaxInvoicesProps() {
      return {
        files: this.data.allTaxes,
        ...this.fileListCard.taxes,
        hasAddPermission: this.$hasPermission(
          'properties',
          'taxInvoices',
          'add'
        )
      }
    },
    filteredActions() {
      return this.user._id === this.client._id
        ? this.actions.filter(
            (action) => action.method !== 'showSendMessageModal'
          )
        : this.actions
    }
  },
  methods: {
    ...mapMutations('appState', [
      'addNotification',
      'setClientForRoute',
      'setClientTypeForRoute'
    ]),
    setConstantDataModel({ claimsTypes, notificationTypes }) {
      this.claimsTypes = claimsTypes
      this.notificationTypes = notificationTypes
    },
    confirmDeletion(bankAccount, accountIndex) {
      if (!this.$hasPermission('users', 'bankAccountsSettings', 'delete')) {
        return this.addNotification({
          text: 'ليس لديك صلاحيه لتعديل الحسابات البنكيه',
          color: 'error'
        })
      }
      return this.$root.$confirm
        .open({
          title: `حذف بيانات ${bankAccount?.bankName}`,
          message: `هل أنت متأكد من حذف بيانات ${bankAccount?.bankName}`,
          options: {
            color: 'red'
          }
        })
        .then(async (confirm) => {
          if (confirm) {
            await this.deleteBankAccounts(bankAccount._id, accountIndex)
          }
        })
    },
    async deleteBankAccounts(bankAccountId, accountIndex) {
      try {
        await EnvService.deleteBankAccounts(bankAccountId)
        this.addNotification({
          text: 'تم حذف بيانات الحساب البنكي بنجاح',
          color: 'success'
        })
        this.data.allBankAccounts.splice(accountIndex, 1)
      } catch {
        this.addNotification({
          text: 'لم نتمكن من حذف بيانات البنك في الوقت الحالي, حاول في وقت لاحق',
          color: 'error'
        })
      }
    },
    setBankAccountDetails(value) {
      if (!this.$hasPermission('users', 'bankAccountsSettings', 'update')) {
        return this.addNotification({
          text: 'ليس لديك صلاحيه لتعديل الحسابات البنكيه',
          color: 'error'
        })
      }
      return () => {
        this.editBankAccountDialogState = true
        this.bankAccountDetails = { ...value }
      }
    },
    toggleShowStats() {
      this.cardsInfoState = !this.cardsInfoState
    },
    setDate({ dateType, date }) {
      this.cardsInfoState = true
      this.datesQuery[`${dateType}`] = date
      this.refreshState += 1
    },
    async getContractIntervals() {
      try {
        const Response = await contractsService.getContractIntervals()
        this.contractIntervals = Response.data.intervals
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل فترات العقد, برجاء المحاوله لاحقا',
          color: 'error'
        })
      }
    },
    setFinancialActiveTab(tabIndex) {
      this.activeFinancialTabIndex = tabIndex
    },
    setLoadingVal(type, value) {
      this.$set(this.fileListCard[type], 'fileListLoading', value)
    },
    // Pagination
    callNextPage(title) {
      const { bonds, taxes, contracts, financialInfo, smsMessages } =
        this.fileListCard
      // Bonds
      if (
        title === bonds.title &&
        this.paginationBonds.pageNumber < this.paginationBonds.pagesCount
      ) {
        this.paginationBonds.pageNumber += 1
        this.getAllBonds()
      }
      // Taxes
      if (
        title === taxes.title &&
        this.paginationTaxes.pageNumber < this.paginationTaxes.pagesCount
      ) {
        this.paginationTaxes.pageNumber += 1
        this.getAllTaxes()
      }
      // Contracts
      if (
        title === contracts.title &&
        this.paginationContracts.pageNumber <
          this.paginationContracts.pagesCount
      ) {
        this.paginationContracts.pageNumber += 1
        this.getAllContracts()
      }
      // financialCard
      if (title === financialInfo.title) {
        this.paginationFinancial.pagesCount = Math.ceil(
          this.activeFinancialTabsLimitCounts /
            this.paginationFinancial.pageSize
        )
        if (
          this.paginationFinancial.pageNumber <
          this.paginationFinancial.pagesCount
        ) {
          this.paginationFinancial.pageNumber += 1
          this.getAllFinancialData()
        }
      }
      // smsMessages
      if (
        title === smsMessages.title &&
        this.paginationSms.pageNumber < this.paginationSms.pagesCount
      ) {
        this.paginationSms.pageNumber += 1
        this.getAllSmsMessages()
      }
    },
    recalculatePagination(type, res) {
      this[type].pagesCount = Math.ceil(res?.count / this[type]?.pageSize)
      this[type].count = res?.count
    },
    getRoleDependEnvironment(envs) {
      if (envs.length > 1) {
        envs.forEach((env) => {
          if (env._id === this.currentEnv._id) {
            this.setClickedTag = env.role[0]
            this.client.environments = [env]
          }
        })
        return
      }
      this.setClickedTag = envs[0].role[0]
    },
    // Get all information this client
    async loadClient() {
      try {
        const { data } = await userService.getSingleClient(this.id)
        this.client = data.user
        this.breadcrumbs.splice(1, 1, {
          text: `${this.client.name}`,
          disabled: true
        })

        // Get role depend on an environment
        this.getRoleDependEnvironment(this.client.environments)
      } catch {
        this.addNotification({
          text: 'خطا في تحميل بيانات العميل',
          color: 'error'
        })
      }
    },
    // Invitation
    async inviteClient({ email }) {
      this.client.email = email
      this.isLoading = true
      try {
        await customersService.inviteCustomer(this.inviteBody)
        this.addNotification({
          text: 'تم ارسال الدعوه',
          color: 'success'
        })
        this.clientInvitationStatus()
      } catch {
        this.addNotification({
          text: 'لم نتمكن من ارسال الدعوه برجاء المحاوله لاحقا',
          color: 'error'
        })
        this.client.email = ''
      } finally {
        this.isLoading = false
        this.closeAddDialog()
      }
    },
    async clientInvitationStatus() {
      try {
        const { data } = await customersService.clientInvitationStatus(
          this.client._id,
          this.currentEnv?._id
        )
        this.statusInviteClient = data
      } catch {}
    },
    // Get all taxes - contracts - bonds
    async getAllTaxes() {
      this.setLoadingVal('taxes', true)
      const query = `environment=${this.currentEnv._id}&pageSize=${this.paginationTaxes.pageSize}&pageNumber=${this.paginationTaxes.pageNumber}&`
      const ownerQuery = this.selectedRealEstate
        ? `realEstate=${this.selectedRealEstate}`
        : `${this.renter === 'renter' ? 'renter=' : 'owner='}${this.id}`
      try {
        const { data } = await taxBillsService.getAllTaxBills(
          query + ownerQuery
        )
        this.taxes = data?.taxBills ?? []
        this.data.allTaxes = [...this.data.allTaxes, ...this.taxes]
        this.recalculatePagination('paginationTaxes', data)
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل الفواتير في الوقت الحالي',
          color: 'error'
        })
      } finally {
        this.setLoadingVal('taxes', false)
      }
    },
    async getAllContracts() {
      const query = this.selectedRealEstate
        ? {
            realEstate: this.selectedRealEstate
          }
        : {
            users: this.id,
            environment: this.currentEnv._id,
            userType: this.clientRoles[this.setClickedTag]
          }
      const contractQuery = `pageSize=${this.paginationContracts.pageSize}&pageNumber=${this.paginationContracts.pageNumber}`
      this.setLoadingVal('contracts', true)
      try {
        const { data } = await contractsService.getContracts(
          query,
          contractQuery
        )
        this.contracts = data?.contracts ?? []
        this.data.allContracts = [...this.data.allContracts, ...this.contracts]
        this.recalculatePagination('paginationContracts', data)
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل العقود في الوقت الحالي',
          color: 'error'
        })
      } finally {
        this.setLoadingVal('contracts', false)
      }
    },
    async getAllBonds() {
      const query = this.selectedRealEstate
        ? {
            realEstate: this.selectedRealEstate
          }
        : {
            client: this.id,
            clientType:
              this.clientRoles[this.setClickedTag] === 'owner'
                ? 'owner'
                : 'renter',
            environment: this.currentEnv._id
          }
      const bondQuery = `pageSize=${this.paginationBonds.pageSize}&pageNumber=${this.paginationBonds.pageNumber}`
      this.setLoadingVal('bonds', true)
      try {
        const { data } = await bondsService.getAllBonds(query, bondQuery)
        this.bonds = data?.bonds ?? []
        this.data.allBonds = [...this.data.allBonds, ...this.bonds]
        this.recalculatePagination('paginationBonds', data)
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل السندات في الوقت الحالي',
          color: 'error'
        })
      } finally {
        this.setLoadingVal('bonds', false)
      }
    },
    async getAllSmsMessages() {
      // In Dev we send messages only to Eng.Muath phone number
      const query = `user=${this.id}&environment=${this.currentEnv._id}&pageSize=${this.paginationSms.pageSize}&pageNumber=${this.paginationSms.pageNumber}`
      this.setLoadingVal('smsMessages', true)
      try {
        const { data } = await messageService.getClientSmsTexts(query)
        this.smsMessages = data.notificationLogsResult ?? []
        if (this.paginationSms.pageNumber > 1) {
          this.data.allSmsMessages = [
            ...this.smsMessages,
            ...this.data.allSmsMessages
          ]
        } else {
          this.data.allSmsMessages = [...this.smsMessages]
        }
        this.smsMessagesCount = data.count
        this.recalculatePagination('paginationSms', data)
      } catch {
        this.addNotification({
          text: 'خطأ في استرجاع الرسائل النصية للعميل، يرجى المحاوله مجددا',
          color: 'error'
        })
      } finally {
        this.setLoadingVal('smsMessages', false)
      }
    },
    async getClientBankAccounts() {
      try {
        this.setLoadingVal('bankAccounts', true)
        const { data } = await EnvService.getEnvOrClientBankAccounts(
          this.currentEnv?._id,
          this.client?._id
        )
        this.data.allBankAccounts = data.bankAccounts ?? []
      } catch {
        this.addNotification({
          text: 'حدث خطأ أثناء تحميل حسابات العميل',
          color: 'error'
        })
      } finally {
        this.setLoadingVal('bankAccounts', false)
      }
    },
    async getAllFinancialData() {
      this.setLoadingVal('financialInfo', true)
      const queryParams = {
        environmentId: this.currentEnv._id,
        role: this.clientRoles[this.setClickedTag],
        pageNumber: this.paginationFinancial.pageNumber,
        pageSize: this.paginationFinancial.pageSize
      }
      try {
        const response = await contractsService.getFinancialData(
          this.id,
          queryParams
        )
        this.handleFinancialDataLogic(response)
      } catch {
        this.addNotification({
          text: 'حدث خطأ أثناء عرض المعلومات الماليه برجاء المحاوله لاحقا',
          color: 'error'
        })
      } finally {
        this.setLoadingVal('financialInfo', false)
      }
    },
    handleFinancialDataLogic(response) {
      Object.keys(response.data).forEach((key) => {
        const pushedObj = {
          nameEn: key,
          ...response.data[key]
        }
        this.financialData.push(pushedObj)
        this.financialTabsLimitCounts.push(pushedObj.count)
      })
      if (!this.data.allFinancialData.length) {
        this.data.allFinancialData = [...this.financialData]
      } else {
        this.data.allFinancialData.map((card, i) => {
          card.data = [...card.data, ...this.financialData[i].data]
        })
      }
    },
    // When change between renter and buyer
    clientChange(type) {
      if (this.setClickedTag === type) {
        return
      }
      this.filterID = ''
      this.setClickedTag = type
      this.paginationTaxes.pageNumber = 1
      this.data.allTaxes = []
      this.data.allContracts = []
      if (this.selectedRealEstate) {
        this.selectedRealEstate = ''
        this.getAllDocuments()
      }
      // this.getAllFinancialData()
      this.data.allBonds = []
      this.getAllBonds()
      this.getAllContracts()
      this.getAllTaxes()
      this.setClientTypeForRoute(type)
    },
    async getPropertyMeta(val = this.id, realEstate) {
      try {
        this.filterID = val
        this.data.allContracts =
          this.data.allBonds =
          this.data.allTaxes =
          this.data.allDocuments =
            []

        if (realEstate) {
          this.selectedRealEstate = val
          this.getRealEstateDocuments()
        } else {
          this.selectedRealEstate = ''
          this.getAllDocuments()
          // this.getAllFinancialData()
        }
        this.getAllContracts()
        this.getAllBonds()
        this.getAllTaxes()
        this.getAllSmsMessages()
        this.getClientBankAccounts()
      } catch {
        this.addNotification({
          text: 'خطا في تحميل البيانات',
          color: 'error'
        })
      }
    },
    // Get - add - del => documents
    async getAllDocuments() {
      this.setLoadingVal('documents', true)
      try {
        const { data } = await userService.getDocuments(this.id)
        this.data.allDocuments = data.result
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل الوثائق في الوقت الحالي ',
          color: 'error'
        })
      } finally {
        this.setLoadingVal('documents', false)
      }
    },
    async getRealEstateDocuments() {
      this.setLoadingVal('documents', true)
      try {
        const { data } = await realEstateService.getDocuments(
          this.selectedRealEstate
        )
        this.$set(this.data, 'allDocuments', data.attachments)
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل الوثائق في الوقت الحالي ',
          color: 'error'
        })
      } finally {
        this.setLoadingVal('documents', false)
      }
    },
    async addDocument(file) {
      try {
        const fd = new FormData()
        fd.append('attachment', file, file.name)
        fd.append('entityId', this.client._id)
        fd.append('envId', this.currentEnv._id)

        const { data } = await userService.addDocument(fd)
        const attachment = data.attachment
        this.data.allDocuments.push(attachment)
        this.addNotification({
          text: 'تم إضافه الوثيقة بنجاح',
          color: 'success'
        })
      } catch (error) {
        if (error.response.data.error === 'feature not allowed') {
          this.addNotification({
            text: 'اضافة الوثائق غير متاحه في الباقة الحالية، يجب الترقية لتتمكن من اضافة الوثائق',
            color: 'error'
          })
        } else {
          this.addNotification({
            text: 'لم يتم إضافة الوثيقة يرجى المحاولة مره اخرى',
            color: 'error'
          })
        }
      }
    },
    async deleteDocument(file) {
      try {
        await userService.deleteDocument(file.fileId)
        this.data.allDocuments.splice(file.fileIndex, 1)
        this.addNotification({
          text: 'تم حذف الوثيقه بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء المحاوله مرة اخرى',
          color: 'error'
        })
      }
    },
    openDocumentsInput() {
      this.$refs.documentsInput.$refs.input.click()
    },
    // RealEstates
    getRealEstates(realEstate) {
      this.realEstates = realEstate
    },
    setRealEstatesResponse(realEstate) {
      this.realEstatesResponse = realEstate.realestateLength
      this.realEstatesCount = realEstate.realEstatesCount
    },
    // Modals
    toggleModel(modelName, status) {
      if (modelName === 'dialogStateForClient' && status) {
        this.addTag = true
      }
      this.$set(this.status, modelName, status)
    },
    openInfoClientModal() {
      this.addTag = true
      this.$set(this.status, 'dialogStateForClient', true)
    },
    openAddContractModal() {
      this.$set(this.status, 'showAddContractModal', true)
    },
    openAddTaxModal() {
      this.$set(this.status, 'showTaxInfoiceModel', true)
    },
    openAddBondModal() {
      this.$set(this.status, 'showAddBondModal', true)
    },
    openActivityLogsModal() {
      this.$set(this.status, 'showActivityLogs', true)
    },
    openModelInviteClient() {
      this.$set(this.status, 'showModelInviteClient', true)
    },
    closeAddDialog() {
      this.status = {}
    },
    closeContractDialog(status) {
      this.closeAddDialog()
      if (status !== 'close') {
        this.data.allContracts = []
        this.paginationContracts.pageNumber = 1
        this.getAllContracts()
      }
    },
    refreshBonds() {
      this.refreshState += 1
      this.data.allBonds = []
      this.paginationBonds.pageNumber = 1
      this.getAllBonds()
    },
    refreshTaxes() {
      this.data.allTaxes = []
      this.paginationTaxes.pageNumber = 1
      this.getAllTaxes()
    },
    openSendMessageModal() {
      this.$set(this.status, 'showSendMessageModal', true)
    }
  },
  metaInfo() {
    return {
      title: 'العملاء'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../src/styles/clients-page/clients.scss';
@import '../../src/styles/responsiveness/responsiveness.scss';
</style>